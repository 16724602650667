import SubHeader from '@/components/shared/sub-header/SubHeader';
import ContentHTML from '@/components/shared/template-partial/ContentHTML';
import { DefaultBody } from '@/models/interfaces/pages/bodies/default';

interface DefaultTemplateProps {
  body: DefaultBody;
}

const DefaultTemplate = ({ body }: DefaultTemplateProps) => {
  const { title, subtitle, breadcrumbs, content, image } = body || {};

  return (
    <div className="flex h-full w-full flex-col">
      <SubHeader title={title} subtitle={subtitle} breadcrumbs={breadcrumbs} />

      <div className="container py-10  md:py-20">
        {/* Description */}
        <ContentHTML content={content} />
      </div>
    </div>
  );
};

export default DefaultTemplate;
