import { Layout, Meta } from '@/components';
import useTopicTemplate from '@/hook/templates/useTopicTemplate';
import { PageApi } from '@/lib/api/PageApi';
import { NextPageData, RootPage } from '@/models/interfaces/pages/root';
import { GetStaticProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Error from '../_error';

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale, preview, params, previewData } = context || {};

  const { lang = locale } = (previewData as any) ?? {};

  const res = await PageApi.get<RootPage<any> & { error: number; statusText: string }>(
    params?.topic as string,
    lang,
    preview,
  );

  // if (res?.error) {
  //   return {
  //     notFound: true,
  //   };
  // }

  // http://localhost:3000/api/preview?slug=/ricette&secret=7c612c50-275a-4887-a8a0-207ad78a9da6

  //http://localhost:3000/api/preview?slug=http://localhost:3000/pagina-slug-test&secret=7c612c50-275a-4887-a8a0-207ad78a9da6

  return {
    props: {
      ...(await serverSideTranslations(lang ?? '', ['common'])),
      res,
      preview: preview ?? false,
      error: res?.error ?? null,
    },
    revalidate: 10,
  };
};

const TopicPage: NextPage<NextPageData<RootPage<any>>> = ({ res, error, preview, previewData }) => {
  const { body, lang, menu, meta } = res ?? {};

  const type = body?.template?.type ?? 'default';

  const ComponentTemplate = useTopicTemplate({ type: type, body: body });

  if (error) {
    return <Error statusCode={error} statusMessage={'Errore'} />;
  }

  return (
    <Layout changeHeaderOnScroll menu={menu} preview={preview}>
      <Meta meta={meta} lang={lang} />
      <div className="flex h-full w-full items-start">{<ComponentTemplate />}</div>
    </Layout>
  );
};

export default TopicPage;
