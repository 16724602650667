import FilterBar, { CategoryFilterType } from '@/components/shared/filter-bar/FilterBar';
import SubHeader from '@/components/shared/sub-header/SubHeader';
import GridCardWall from '@/components/shared/wall/GridCardWall';
import { SearchDataFetch } from '@/models/interfaces/utils/utils';
import { GeneralCardType } from '@/models/types/types';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import GalMenu from '../FilieraArchiveTemplate/components/GalMenu';
import useMainContainer from '@/hook/useMainContainer';
import { ArchiveBody } from '@/models/interfaces/pages/bodies/archive/archive';
import Pagination, { SearchParamsType } from '@/components/shared/pagination/Pagination';
import { EntityType } from '@/models/entity-type/EntityType';
import ButtonDateRangePicker from '@/components/shared/button-date-range-picker/ButtonDateRangePicker';
import { extractCategoryFilter, extractGalFilter } from '@/components/utils/utils';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { XCircleIcon } from '@heroicons/react/24/outline';

interface EventArchiveTemplateProps {
  body: ArchiveBody;
}

const EventArchiveTemplate = ({ body }: EventArchiveTemplateProps) => {
  const [data, setData] = useState<SearchDataFetch<GeneralCardType[]>>();
  const [selectedCategory, setSelectedCategory] = useState<string>('1000');
  const [selectedGal, setSelectedGal] = useState<string>('1000');
  const [selectedDate, setSelectedDate] = useState<(Date | null)[]>([null, null]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { title, subtitle, breadcrumbs, taxonomies } = body ?? {};

  const refSubHeader = useRef<HTMLDivElement>(null);

  const { ref: refMainContainer } = useMainContainer();

  const { i18n } = useTranslation();

  const filters: CategoryFilterType[][] = useMemo(() => {
    return extractCategoryFilter(taxonomies);
  }, [taxonomies]);

  const galFilters = useMemo(() => {
    return extractGalFilter(taxonomies);
  }, [taxonomies]);

  const searchParams: SearchParamsType = useMemo(() => {
    return {
      gal: selectedGal !== '1000' ? selectedGal : undefined,
      category: selectedCategory !== '1000' ? selectedCategory : undefined,
      lang: i18n.language,
      limit: 10,
      from: selectedDate[0] ? moment(selectedDate[0]).format('YYYY-MM-DD').toString() : undefined,
      to: selectedDate[1] ? moment(selectedDate[1]).format('YYYY-MM-DD').toString() : undefined,
    };
  }, [selectedGal, selectedCategory, i18n.language, selectedDate]);

  const handleSelectCategory = useCallback((item: string) => {
    setSelectedCategory(item);
  }, []);

  const handleSelectGal = useCallback((gal: string) => {
    setSelectedGal(gal);
  }, []);

  const handleChangePage = useCallback(
    (page: number, isFirstLoading: boolean) => {
      const heightSubHeader = refSubHeader.current?.offsetHeight ?? 0;

      if (!isFirstLoading)
        refMainContainer.current?.scrollTo({
          top: heightSubHeader,
        });
    },
    [refMainContainer],
  );

  return (
    <div className=" w-full">
      <SubHeader title={title} subtitle={subtitle} breadcrumbs={breadcrumbs} className="" />
      <div className="container flex flex-grow flex-col justify-between pb-10">
        <div className="gap-4 space-y-2 py-8">
          <div
            className={'relative flex max-w-full flex-wrap items-center justify-between gap-2 xs:flex-nowrap'}
          >
            <div className={'relative flex max-w-full flex-wrap items-center justify-start gap-2'}>
              <GalMenu onSelect={handleSelectGal} selected={selectedGal} menu={galFilters} />

              <div className="mr-2 h-10 w-px min-w-[1px] bg-stone-200" />

              <div className="flex items-center gap-2">
                <ButtonDateRangePicker
                  align="left"
                  onStartDateChange={(date) => {
                    date && setSelectedDate((prev) => [date, prev[1]]);
                  }}
                  onEndDateChange={(date) => {
                    date && setSelectedDate((prev) => [prev[0], date]);
                  }}
                  startDate={selectedDate[0]}
                  endDate={selectedDate[1]}
                />
                {selectedDate[1] ? (
                  <XCircleIcon
                    className="h-6 w-6 animate-jackInTheBox cursor-pointer text-red-700 "
                    strokeWidth={2}
                    onClick={() => setSelectedDate([null, null])}
                  />
                ) : null}
              </div>

              <div className="mx-2 h-10 w-px min-w-[1px] bg-stone-200" />

              <FilterBar
                categoryFilters={filters[0]}
                selectedCategory={selectedCategory}
                onSelectCategory={handleSelectCategory}
                className="sky-blue"
              />
            </div>
          </div>
        </div>

        <GridCardWall
          items={data?.results ?? []}
          isLoading={isLoading}
          isEmpty={data?.results?.length === 0}
        />

        <Pagination<SearchDataFetch<GeneralCardType[]>>
          url={'search/' + EntityType.EVENT}
          pagination={data?.pagination}
          searchParams={searchParams}
          onResults={(data) => {
            setData(data);
          }}
          onFetching={setIsLoading}
          onMoveToPage={handleChangePage}
          activePageClassName="bg-primary-500 !text-white !hover:bg-primary-500 !cursor-not-allowed"
          textColorPageClassName="text-primary-500 hover:bg-primary-50"
        />
      </div>
    </div>
  );
};

export default EventArchiveTemplate;
