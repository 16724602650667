import { EntityType } from '@/models/entity-type/EntityType';
import { CardType, GeneralCardType } from '@/models/types/types';
import React, { useMemo } from 'react';
import PrimaryCard from './PrimaryCard';
import EventCard from './EventCard';
import FilieraArchiveCard from './FilieraArchiveCard';

interface MainCardProps<T = CardType> {
  item?: Partial<GeneralCardType | T>;
  className?: string;
}

function MainCard<T>({ className, item }: MainCardProps<T>) {
  const ComponentCard = useMemo(() => {
    const { type } = item || ({} as any);
    const formatType = type?.toLowerCase();

    switch (formatType) {
      case EntityType.EVENT:
        return EventCard;
      case EntityType.RISTORANTI:
        return FilieraArchiveCard;
      case EntityType.AGRITURISMI:
        return FilieraArchiveCard;
      case EntityType.CANTINE:
        return FilieraArchiveCard;
      case EntityType.FRANTOI:
        return FilieraArchiveCard;

      default:
        return PrimaryCard;
    }
  }, [item]);

  return <ComponentCard item={item as any} className={className} />;
}

export default MainCard;
