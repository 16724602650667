import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import SubHeader from '@/components/shared/sub-header/SubHeader';
import dynamic from 'next/dynamic';

const DynamicGalSection = dynamic(() => import('@/components/shared/gal/GalSection'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

interface GalArchiveTemplateProps {
  body: any;
}

const GalArchiveTemplate = ({ body }: GalArchiveTemplateProps) => {
  const { gal, breadcrumbs, title, subtitle } = body ?? {};

  return (
    <div className="w-full">
      <SubHeader title={title} subtitle={subtitle} breadcrumbs={breadcrumbs} />

      {/* <div className={clsx('container py-20', 'lg:flex')}>
        <div className={clsx('w-full ', 'lg:flex lg:flex-col lg:justify-center lg:gap-8')}>
          <h2 className="">Esplora il territorio</h2>
          <p className="mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
            labore et dolore magna aliqua. Ut enim ad minim veniam
          </p>

          <div className="mt-5 lg:hidden">
            <CustomListbox
              items={Array.from({
                length: 10,
              }).map((_, i) => ({
                id: i.toString(),
                label: `Item ${i}`,
                value: `Item ${i}`,
              }))}
              defaultSelected={null}
              placeholder="Seleziona un territorio"
              onSelect={(item) => console.log(item)}
            />
          </div>

          <div className="mt-9 flex gap-3">
            <div className="h-fit w-fit rounded-full bg-primary-500 p-2">
              <HiCursorClick className="h-5 w-5 rotate-90 text-white" />
            </div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
          </div>
        </div>

        <div className="relative hidden h-[550px] w-full lg:block">
          <GalMap />
        </div>
      </div> */}

      <div className="py-20">
        <DynamicGalSection gal={gal} />
      </div>
    </div>
  );
};

export default GalArchiveTemplate;
