/* eslint-disable react/display-name */
import FilieraArchiveTemplate from '@/components/templates/topic-templates/FilieraArchiveTemplate/FilieraArchiveTemplate';
import { EntityType } from '@/models/entity-type/EntityType';
import { memo, useMemo, useState } from 'react';
import ArchiveTemplate from '@/components/templates/topic-templates/ArchiveTemplate/ArchiveTemplate';
import GalArchiveTemplate from '@/components/templates/topic-templates/GalArchiveTemplate/GalArchiveTemplate';
import EventArchiveTemplate from '@/components/templates/topic-templates/EventArchiveTemplate/EventArchiveTemplate';
import DefaultTemplate from '@/components/templates/slug-templates/DefaultTemplate/DefaultTemplate';

interface Props {
  type: EntityType;
  body: any;
}

const useTopicTemplate = ({ type, body }: Props) => {
  const ComponentTemplate = useMemo(() => {
    const formatType = type?.toLowerCase();

    switch (formatType) {
      // FILIERA
      case EntityType.CANTINE:
        return <FilieraArchiveTemplate body={body} type={EntityType.CANTINE} />;
      case EntityType.AGRITURISMI:
        return <FilieraArchiveTemplate body={body} type={EntityType.AGRITURISMI} />;
      case EntityType.FRANTOI:
        return <FilieraArchiveTemplate body={body} type={EntityType.FRANTOI} />;
      case EntityType.RISTORANTI:
        return <FilieraArchiveTemplate body={body} type={EntityType.RISTORANTI} />;

      // OTHER
      case EntityType.ITINERARI:
        return <ArchiveTemplate body={body} type={EntityType.ITINERARI} />;

      case EntityType.PRODOTTI:
        return <ArchiveTemplate body={body} type={EntityType.PRODOTTI} />;

      case EntityType.GAL:
        return <GalArchiveTemplate body={body} />;

      case EntityType.RICETTE:
        return <ArchiveTemplate body={body} type={EntityType.RICETTE} />;

      case EntityType.EVENT:
        return <EventArchiveTemplate body={body} />;

      case EntityType.PAGE:
        return <ArchiveTemplate body={body} type={EntityType.PAGE} />;

      case EntityType.CONSORZIO:
        return <ArchiveTemplate body={body} type={EntityType.CONSORZIO} />;

      case EntityType.PLACE:
        return <ArchiveTemplate body={body} type={EntityType.PLACE} />;

      default:
        return <DefaultTemplate body={body} />;
    }
  }, [type, body]);

  // Memoizing the function returned by useSlugTemplate
  const MemoizedComponent = memo(() => ComponentTemplate);

  // Aggiungi displayName alla funzione memoizzata
  MemoizedComponent.displayName = 'MemoizedComponentTemplate';

  return MemoizedComponent;
};

export default useTopicTemplate;
