import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useHeaderContainer from '@/hook/useHeaderContainer';
import clsx from 'clsx';
import FilieraArchiveCard, { ArchiveItem } from '../../../shared/cards/FilieraArchiveCard';
import { ThemeContext } from '../../../shared/utility/theme/ThemeContext';
import Pagination, { SearchParamsType } from '../../../shared/pagination/Pagination';
import useBreakPoint from '@/hook/useBreakPoint';
import { SearchDataFetch } from '@/models/interfaces/utils/utils';
import useMainContainer from '@/hook/useMainContainer';
import { useTranslation } from 'next-i18next';
import FilterBar, { CategoryFilterType, GalFilterType } from '../../../shared/filter-bar/FilterBar';
import DynamicSubHeader from './components/DynamicSubHeader';
import { EntityType, FilieraEntityType } from '@/models/entity-type/EntityType';
import { useRouter } from 'next/router';
import { FilieraBody } from '@/models/interfaces/pages/bodies/archive/filiera';
import { extractCategoryFilter, extractGalFilter } from '@/components/utils/utils';
import IconMapping from '@/components/utils/iconMapping';
import OverlayLoader from '@/components/shared/overlay/OverlayLoader';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import Button from '@/components/core/buttons/Button';
import { IoPaperPlane } from 'react-icons/io5';
import { Popup } from 'react-map-gl';
import { ID_STELLA_MICHELIN } from '@/constant/constant.utils';
import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import CustomMap from '@/components/shared/maps/Map';
import LoaderMap from '@/components/shared/maps/components/LoaderMap';

const DynamicGalMenu = dynamic(() => import('./components/GalMenu'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const DynamicNotFound = dynamic(() => import('@/components/shared/utility/errors/NotFound'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

interface FilieraArchiveTemplateProps {
  body: FilieraBody;
  type: FilieraEntityType;
}

const FilieraArchiveTemplate = ({ body, type }: FilieraArchiveTemplateProps) => {
  const {
    subtitle,
    title,
    breadcrumbs,
    taxonomies,
    template: { type: filieraEntityType },
  } = body;

  const [data, setData] = useState<SearchDataFetch<any>>();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [isLoadingChangePage, setIsLoadingChangePage] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ArchiveItem | null>(null);

  const [selectedFiliera, setSelectedFiliera] = useState<FilieraEntityType>(type);
  const [selectedCategory, setSelectedCategory] = useState<string>('1000');
  const [selectedGal, setSelectedGal] = useState<string>('1000');

  const [refMap, setRefMap] = useState<any>(null);

  const refMapContainer = useRef<HTMLDivElement | null>(null);
  const refSubHeader = useRef<HTMLDivElement | null>(null);

  const { theme } = useContext(ThemeContext);
  const { greaterThanLg } = useBreakPoint();
  const { t, i18n } = useTranslation();
  const router = useRouter();

  useHeaderContainer(({ height }) => {
    if (refMapContainer.current) {
      refMapContainer.current.style.top = `${height}px`;
    }
  }, 0);

  const { ref: refMainContainer } = useMainContainer();

  const categoryFilters: CategoryFilterType[][] = useMemo(() => {
    return extractCategoryFilter(
      taxonomies?.filter((item) => item.slug !== 'filiera' && item.slug !== 'gal'),
    );
  }, [taxonomies]);

  const filieraFilters: CategoryFilterType[] = useMemo(() => {
    const newItems = extractCategoryFilter(taxonomies, 'filiera')[0];

    newItems?.shift();

    return newItems;
  }, [taxonomies]);

  const galFilters: GalFilterType[] = useMemo(() => {
    return extractGalFilter(taxonomies);
  }, [taxonomies]);

  const dataForMap = useMemo(() => {
    return (
      data?.results?.map((item: any, index: number) => {
        return {
          id: item?.id,
          title: item?.title,
          permalink: item?.permalink,
          latitude: item?.point.lat,
          longitude: item?.point.lng,
        };
      }) ?? []
    );
  }, [data]);

  const handleSelectItem = useCallback((item: any) => {
    setSelectedItem(item);
  }, []);

  const searchParams: SearchParamsType = useMemo(() => {
    handleSelectItem(null);
    return {
      gal: selectedGal !== '1000' ? selectedGal : undefined,
      category: selectedCategory !== '1000' && categoryFilters?.length > 0 ? selectedCategory : undefined,
      lang: i18n.language,
      limit: greaterThanLg ? 150 : 20,
    };
  }, [selectedGal, selectedCategory, categoryFilters, i18n.language, greaterThanLg]);

  const onRefMap = useCallback((ref: any) => {
    setRefMap(ref);
  }, []);

  const handleChangePage = useCallback(
    (page: number, isFirstLoading: boolean) => {
      const heightSubHeader = refSubHeader.current?.offsetHeight ?? 0;

      if (!isFirstLoading && greaterThanLg)
        refMainContainer.current?.scrollTo({
          top: heightSubHeader,
        });
    },
    [refMainContainer],
  );

  const handleSelectFiliera = useCallback(
    (slug: string) => {
      setIsLoadingChangePage(true);

      // const slug = filieraFilters.find((item) => item.id === slug)?.name ?? '';

      router
        .push({
          pathname: `/${slug === 'ristoranti' ? 'ristorazione' : slug}`,
          query: {
            gal: selectedGal,
          },
        })
        .then(() => window.scrollTo(0, 0))
        .catch((err) => console.log(err));
    },
    [filieraFilters, router, selectedGal],
  );

  const handleSelectGal = useCallback((idGal: string) => {
    if (idGal) {
      window.history.pushState(
        {},
        '',
        `/${selectedFiliera === 'ristoranti' ? 'ristorazione' : selectedFiliera}?gal=${idGal}`,
      );

      setSelectedGal(idGal);
    }
  }, []);

  useEffect(() => {
    setSelectedFiliera(type);
  }, [type]);

  useEffect(() => {
    if (refMap) {
      refMap?.getMap().resize();
    }
  }, [router.query, refMap]);

  useEffect(() => {
    if (router?.isReady) {
      if (router?.query.gal && router?.query.gal !== selectedGal) {
        setSelectedGal(router?.query.gal as string);
      }
    }
  }, [router?.isReady]);

  return (
    <div className={'h-full w-full ' + type}>
      {/* Sub Header */}
      <DynamicSubHeader
        ref={refSubHeader}
        breadcrumbs={breadcrumbs}
        entity={filieraEntityType as FilieraEntityType}
        subtitle={subtitle}
        title={title}
      />

      {/* Map - Results */}
      <div className="flex h-auto w-full flex-col-reverse gap-4 lg:flex-row lg:gap-0">
        {/* Results */}
        <div
          className={clsx(
            'relative h-full w-full p-5 pt-2',
            'lg:max-w-lg lg:pt-4',
            'xl:max-w-3xl',
            'lg:p 2xl:max-w-4xl',
          )}
        >
          <div className={'relative z-20 flex max-w-full flex-wrap items-center justify-start gap-2'}>
            <div className="relative flex w-full items-center justify-start gap-4">
              <div className="flex snap-x gap-2 overflow-x-scroll pr-4 scrollbar-hide">
                {filieraFilters?.map((item, index) => {
                  const name =
                    item?.name.toLowerCase() === 'ristorazione'
                      ? EntityType.RISTORANTI
                      : (item?.name as FilieraEntityType);

                  const type = item?.type as FilieraEntityType;

                  const IconComponent = IconMapping[type as FilieraEntityType]?.thin;

                  return (
                    <button
                      key={item?.id}
                      className={clsx(
                        'flex !min-w-[7rem] snap-start flex-col items-center gap-1 whitespace-nowrap rounded-2xl border-2 border-transparent font-light capitalize text-stone-400 last:mr-8 ',
                        'px-2 py-1.5 text-sm',
                        'xl:px-3.5 xl:py-2 xl:text-base xl:font-light',
                        {
                          'border-primary-500 bg-primary-500 text-white hover:border-primary-600 hover:bg-primary-600':
                            selectedFiliera === type || selectedFiliera === item?.name,
                          'hover:border-primary-500 hover:bg-stone-50 dark:hover:bg-stone-700':
                            selectedFiliera !== item?.name && selectedFiliera !== type,
                        },
                      )}
                      onClick={() => handleSelectFiliera(name)}
                    >
                      {IconComponent && <IconComponent className="h-8 w-8 lg:h-10 lg:w-10" />}
                      {item?.name}
                    </button>
                  );
                })}
              </div>

              <span className="absolute inset-y-0 right-0 w-10 bg-gradient-to-r from-transparent to-white dark:to-dark" />
            </div>

            <div className="mt-2 flex flex-wrap items-center gap-2">
              <DynamicGalMenu
                onSelect={handleSelectGal}
                selected={selectedGal}
                selectedName={
                  selectedGal === '1000'
                    ? t('Seleziona Territorio').toString()
                    : galFilters.find((item) => item.id === selectedGal)?.name
                }
                menu={galFilters}
              />

              {categoryFilters[0]?.length > 0 && <div className="mx-2 h-14 w-px bg-stone-200" />}

              <FilterBar
                categoryFilters={categoryFilters[0]}
                selectedCategory={selectedCategory}
                onSelectCategory={(id) => setSelectedCategory(id)}
              />
            </div>
          </div>

          <div className="my-4 h-px w-full bg-stone-200" />

          <span className="ml-1 block text-sm">
            <strong> {data?.pagination?.total}</strong> {t('risultati')}
          </span>

          {/* Results - Pagination */}
          <div className="mt-5 flex h-full flex-grow flex-col justify-between">
            <div
              className={clsx(
                'relative grid h-full w-full grid-cols-1 gap-5',
                'sm:grid-cols-2',
                'lg:grid-cols-1',
                'xl:grid-cols-2',
              )}
            >
              {!isLoadingData &&
                data?.results?.map((item: any, index: number) => (
                  <div key={item + index.toString()} className="h-40">
                    <FilieraArchiveCard
                      item={{
                        id: item?.id ?? '',
                        title: item?.title ?? '',
                        latitude: item?.point.lat ?? 0,
                        longitude: item?.point?.lng ?? 0,
                        permalink: item?.permalink ?? '',
                      }}
                      url={item?.permalink ?? ''}
                      selected={selectedItem?.id === item.id}
                      type={selectedFiliera}
                      preventDefault={true}
                      isStar={item?.category?.id === ID_STELLA_MICHELIN}
                      onClick={(item) => {
                        setSelectedItem(item);
                      }}
                      // onMouseEnter={(item) => setSelectedItem(item)}
                      // onMouseLeave={(item) => setSelectedItem(item)}
                    />
                  </div>
                ))}

              {isLoadingData &&
                Array.from({ length: 10 }).map((_, index) => (
                  <div
                    key={index}
                    className="h-40 overflow-hidden rounded-xl bg-stone-100 animate-infinite dark:bg-stone-700"
                  >
                    <div className="h-full w-full animate-translate-x">
                      <span
                        className="relative block h-full w-40 animate-pulse 
                        bg-gradient-to-r from-transparent via-stone-50 to-stone-100 animate-delay-2s dark:via-stone-600 dark:to-stone-700"
                      />
                    </div>
                  </div>
                ))}
            </div>

            {!isLoadingData && !data?.results?.length && (
              <div className="flex h-full w-full flex-grow flex-col items-center justify-center">
                <div className="">
                  <DynamicNotFound />
                </div>
              </div>
            )}

            <div className="flex h-full flex-grow scale-95 items-end justify-center pt-14">
              <Pagination
                url={'search/' + type}
                searchParams={searchParams}
                onFetching={(value) => {
                  setIsLoadingData(value);
                  if (value)
                    setData({
                      ...data,
                      results: [],
                    } as any);
                }}
                onResults={(data: any) => {
                  setData(data);
                  // console.log('onResults', data);
                }}
                onMoveToPage={handleChangePage}
                activePageClassName="bg-primary-500 !text-white !hover:bg-primary-500 !cursor-not-allowed"
                textColorPageClassName="text-primary-500 hover:bg-primary-50"
              />
            </div>
          </div>
        </div>

        {/* Map */}
        <div
          ref={refMapContainer}
          className={clsx('sticky z-20 ', ' lg:z-10 lg:flex-grow lg:py-4 ')}
          style={{
            height: greaterThanLg ? `calc(100vh - 5rem)` : 'auto',
          }}
        >
          <div className="shadow-full shadow-full relative h-[240px] w-full flex-grow overflow-hidden rounded-b-2xl md:h-[300px] lg:h-full lg:rounded-l-2xl">
            <LoaderMap isLoading={true} />

            <CustomMap
              items={dataForMap}
              selectedItem={selectedItem}
              theme={theme}
              isLoading={isLoadingData}
              onRef={onRefMap}
              onSelectItem={(item: any) => {
                handleSelectItem(item);
              }}
            >
              {selectedItem ? (
                <Popup
                  longitude={selectedItem?.longitude || 0}
                  latitude={selectedItem?.latitude || 0}
                  closeOnClick={true}
                  onClose={() => handleSelectItem?.(null)}
                  className="custom-popup relative z-[1000] animate-fadeIn bg-transparent animate-duration-500"
                  closeButton={false}
                  style={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <h3 className="mb-3 text-lg font-semibold">{selectedItem?.title}</h3>

                  <Button
                    variant="primary"
                    size="sm"
                    className="!ml-auto !mt-4 flex w-full items-center justify-center !px-4 !text-sm"
                    onClick={() => {
                      window.open(selectedItem?.permalink, '_blank');
                    }}
                  >
                    <IoPaperPlane className="mr-2 h-3.5 w-3.5" />
                    {t('Scopri di più')}
                  </Button>
                </Popup>
              ) : null}
            </CustomMap>
          </div>
        </div>
      </div>

      <div className="mt-10 bg-gradient-to-r from-primary-600 to-primary-300">
        <div className="mx-auto max-w-3xl py-8 text-center">
          <p className="text-2xl font-medium text-white">
            {t(
              'Sei titolare o gestore di una struttura e non trovi la tua attività o hai trovato dati errati?',
            )}
            <Link
              href="/contatti"
              className="group ml-2 inline-block w-fit font-semibold text-primary-700 duration-300 ease-out hover:text-primary-500"
            >
              {t('Contattaci')}

              <span className="bg-primary block h-0.5 w-0 -translate-y-1.5 duration-300 ease-out group-hover:w-full " />
            </Link>
          </p>
        </div>
      </div>

      <OverlayLoader isLoading={isLoadingChangePage} />
    </div>
  );
};

export default memo(FilieraArchiveTemplate);
