import React, { useEffect, useState } from 'react';
import { it } from 'date-fns/locale';
import { DateRangePickerCalendar, DateRangeFocus, DateChangeCallBack } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';

interface CustomDateRangePickerProps {
  startDate?: Date | null;
  endDate?: Date | null;
  onStartDateChange: DateChangeCallBack;
  onEndDateChange: DateChangeCallBack;
}

const CustomDateRangePicker = ({
  onEndDateChange,
  onStartDateChange,
  endDate,
  startDate,
}: CustomDateRangePickerProps) => {
  const [startDateLocal, setStartDateLocal] = useState<Date | null>();
  const [endDateLocal, setEndDateLocal] = useState<Date | null>();
  const [focus, setFocus] = useState<DateRangeFocus>('startDate');

  const handleFocusChange = (newFocus: DateRangeFocus) => {
    setFocus(newFocus || 'startDate');
  };

  const handleStartDateChange: DateChangeCallBack = (date) => {
    setStartDateLocal(date);
    onStartDateChange(date);
  };

  const handleEndDateChange: DateChangeCallBack = (date) => {
    setEndDateLocal(date);
    onEndDateChange(date);
  };

  useEffect(() => {
    if (startDateLocal !== startDate) {
      setStartDateLocal(startDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (endDateLocal !== endDate) {
      setEndDateLocal(endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return (
    <DateRangePickerCalendar
      startDate={startDateLocal || undefined}
      endDate={endDateLocal || undefined}
      focus={focus}
      onStartDateChange={handleStartDateChange}
      onEndDateChange={handleEndDateChange}
      onFocusChange={handleFocusChange}
      locale={it}
    />
  );
};

export default CustomDateRangePicker;
