import { CardType, EventCardType, GeneralCardType } from '@/models/types/types';
import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { GridLoader } from 'react-spinners';
import MainCard from '../cards/MainCard';
import NotFound from '../utility/errors/NotFound';

interface GridCardWallProps {
  items: Partial<CardType & EventCardType & GeneralCardType>[];
  isLoading: boolean;
  isEmpty: boolean;
}

const GridCardWall = ({ isEmpty, isLoading, items }: GridCardWallProps) => {
  const { t } = useTranslation();

  if (isEmpty) return <NotFound />;

  if (isLoading)
    return (
      <div className="flex min-h-[300px] w-full items-center justify-center">
        <GridLoader color="#A91314" size={20} />
      </div>
    );

  return (
    <div className="grid h-full grid-cols-4 gap-4 pb-10">
      {items?.map((item, index) => {
        // console.log('🚀 ~ file: ArchiveTemplate.tsx:96 ~ item', item);
        const colSpan = index === 0 || index % 5 === 0 ? 'lg:col-span-2' : 'lg:col-span-1';

        return (
          <div
            key={item?.id}
            className={clsx(
              'col-span-4 w-full sm:col-span-2 lg:h-96',
              'aspect-[9/12] xs:aspect-[9/10] sm:aspect-[9/12] md:aspect-[9/10]',
              colSpan,
            )}
          >
            <MainCard item={item} />
          </div>
        );
      })}
    </div>
  );
};

export default memo(GridCardWall);
