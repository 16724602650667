import Pagination, { SearchParamsType } from '@/components/shared/pagination/Pagination';
import SubHeader from '@/components/shared/sub-header/SubHeader';
import GridCardWall from '@/components/shared/wall/GridCardWall';
import { extractCategoryFilter, extractGalFilter } from '@/components/utils/utils';
import useMainContainer from '@/hook/useMainContainer';
import { EntityType, FilieraEntityType } from '@/models/entity-type/EntityType';
import { ArchiveBody } from '@/models/interfaces/pages/bodies/archive/archive';
import { SearchDataFetch } from '@/models/interfaces/utils/utils';
import { GeneralCardType } from '@/models/types/types';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterBar, { CategoryFilterType } from '../../../shared/filter-bar/FilterBar';
import GalMenu from '../FilieraArchiveTemplate/components/GalMenu';

interface ArchiveTemplateProps {
  body: ArchiveBody;
  type: Exclude<EntityType, FilieraEntityType>;
}

const ArchiveTemplate = ({ body, type }: ArchiveTemplateProps) => {
  const [data, setData] = useState<SearchDataFetch<GeneralCardType[]>>();
  const [selectedCategory, setSelectedCategory] = useState<string>('1000');
  const [selectedCategory2, setSelectedCategory2] = useState<string>('1000');
  const [selectedGal, setSelectedGal] = useState<string>('1000');
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { title, subtitle, breadcrumbs, taxonomies } = body;

  const refSubHeader = useRef<HTMLDivElement>(null);

  const { ref: refMainContainer } = useMainContainer();
  const { i18n } = useTranslation();
  const router = useRouter();

  const filters: CategoryFilterType[][] = useMemo(() => {
    return extractCategoryFilter(taxonomies);
  }, [taxonomies]);

  const galFilters = useMemo(() => {
    return extractGalFilter(taxonomies);
  }, [taxonomies]);

  const searchParams: SearchParamsType = useMemo(() => {
    const gal = selectedGal !== '1000' ? selectedGal : '';
    let categories: string[] = [];

    if (selectedCategory !== '1000') categories.push(selectedCategory);

    if (selectedCategory2 !== '1000') categories.push(selectedCategory2);

    const searchParams: Record<string, any> = {
      gal: gal,
      categories: categories.join(','),
      lang: i18n.language,
      limit: 10,
    };

    return {
      ...searchParams,
    };
  }, [selectedGal, selectedCategory, selectedCategory2, i18n.language]);

  const handleSelectGal = useCallback((gal: string) => {
    setSelectedGal(gal);
  }, []);

  useEffect(() => {
    return () => {
      setSelectedCategory('1000');
      setSelectedCategory2('1000');
    };
  }, [router?.query?.topic]);

  useEffect(() => {
    if (router?.isReady) {
      if (router?.query.category) {
        setSelectedCategory2('1000');
        setSelectedCategory(router?.query.category as string);
      }
    }
  }, [router?.isReady, router?.query.category]);

  useEffect(() => {
    setSelectedCategory2('1000');
  }, [selectedCategory]);

  return (
    <div className="flex h-full w-full flex-col">
      <SubHeader ref={refSubHeader} title={title} subtitle={subtitle} breadcrumbs={breadcrumbs} />

      <div className="container flex flex-grow flex-col justify-between pb-10">
        <div className=" gap-4 space-y-2 py-8">
          <div className={'relative flex max-w-full flex-wrap items-center justify-start gap-2'}>
            <GalMenu onSelect={handleSelectGal} selected={selectedGal} menu={galFilters} />

            {filters[0]?.length > 0 && <div className="mx-2 h-10 w-px min-w-[1px] bg-stone-200" />}

            <FilterBar
              categoryFilters={filters[0] ?? []}
              selectedCategory={selectedCategory}
              onSelectCategory={(id) => {
                const query = { ...router?.query, category: id } as any;
                if (id === '1000') delete query?.category;

                setIsLoadingPage(true);
                router
                  .push(
                    {
                      pathname: router.pathname,
                      query: query,
                    },
                    undefined,
                    { shallow: true },
                  )
                  .finally(() => {
                    setIsLoadingPage(false);
                  });
                setSelectedCategory(id);
              }}
              className="sky-blue"
            />

            {filters[1]?.length > 0 && <div className="mx-2 h-10 w-px min-w-[1px] bg-stone-200" />}

            <FilterBar
              categoryFilters={filters[1]}
              selectedCategory={selectedCategory2}
              onSelectCategory={(id) => setSelectedCategory2(id)}
              className="nature"
            />
          </div>
        </div>

        <GridCardWall
          items={data?.results ?? []}
          isLoading={isLoading}
          isEmpty={data?.results?.length === 0}
        />

        <Pagination<SearchDataFetch<GeneralCardType[]>>
          url={'search/' + type}
          searchParams={searchParams}
          onResults={(data) => {
            setData(data);
          }}
          onFetching={setIsLoading}
          activePageClassName="bg-primary-500 !text-white !hover:bg-primary-500 !cursor-not-allowed"
          textColorPageClassName="text-primary-500 hover:bg-primary-50"
        />
      </div>
      {/* <OverlayLoader isLoading={isLoadingPage} /> */}
    </div>
  );
};

export default ArchiveTemplate;
