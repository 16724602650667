/* eslint-disable react/display-name */
import Breadcrumbs from '@/components/core/breadcrumbs/Breadcrumbs';
import Title from '@/components/core/title/Title';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

interface SubHeaderProps {
  title: string;
  subtitle?: string;
  breadcrumbs: { [key: string]: string };
  className?: string;
  titleBar?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
}

const SubHeader = forwardRef<HTMLDivElement, SubHeaderProps>((props, ref) => {
  const { title, subtitle, breadcrumbs, className, titleBar = 'md' } = props;

  return (
    <div
      ref={ref}
      className={clsx(
        'bg-gray-light-dark relative flex h-52 w-full items-center overflow-hidden transition-all duration-300',
        className,
      )}
    >
      {/* <Image blurDataURL="/placeholder.png"  placeholder="blur"  src={category.subHeader} alt="" fill className="object-cover" /> */}
      {/* <div className="absolute inset-0 bg-black/10" /> */}
      <div className="container z-10 flex flex-col gap-6">
        <div className="animate-fadeInDown">
          <Breadcrumbs breadcrumbs={breadcrumbs} className="text-sm lg:text-lg" textColorClass="" />
        </div>

        <div className="relative flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
          <div className="relative w-full animate-fadeInLeft">
            {/* <span className="absolute inset-0 w-2 rounded-full bg-primary-500" /> */}

            <Title className="" sizeBar={titleBar}>
              {title}
            </Title>
          </div>

          {subtitle ? (
            <div className="w-full animate-fadeInUpBig animate-duration-[1200ms]">
              <p className=" ">{subtitle}</p>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export default SubHeader;
