import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ViewPaginate from './components/ViewPaginate';
import useSWR from 'swr';
import { fetcher } from '@/lib/api/fetcher';
import { prepareUrl } from './utils/utils';
import { SearchPaginationFetch } from '@/models/interfaces/utils/utils';
import clsx from 'clsx';

export type SearchParamsType = {
  q?: string;
  limit?: number;
  lang?: string;
  gal?: string;
  category?: string;
  categories?: string;
  from?: string;
  to?: string;
};

interface PaginationProps<T> {
  url?: string;
  textColorPageClassName?: string;
  activePageClassName?: string;
  searchParams?: SearchParamsType;
  pagination?: SearchPaginationFetch;
  onResults?: (items: T) => void;
  onFetching?: (isLoading: boolean) => void;
  onMoveToPage?: (page: number, isFirstLoading: boolean) => void;
}

function Pagination<T>({
  textColorPageClassName,
  activePageClassName,
  searchParams,
  url,
  onResults,
  onFetching,
  onMoveToPage,
}: PaginationProps<T>) {
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
  const [searchParamsState, setSearchParamsState] = useState<SearchParamsType>(searchParams ?? {});
  const [pagination, setPagination] = useState<SearchPaginationFetch>({
    current_page: 1,
    last_page: 1,
    per_page: 0,
    total: 0,
  });

  const { data, error, isLoading } = useSWR(
    prepareUrl(url ?? '', { ...searchParamsState, page: pagination.current_page }),
    fetcher,
  );

  const textClassName = textColorPageClassName ? textColorPageClassName : 'text-primary-500';
  const activeClassName = activePageClassName ? activePageClassName : 'bg-primary-500';

  const onMoveSpecificPage = useCallback(
    (selectedPage: number, action?: 'reset') => {
      // console.log('onMoveSpecificPage', selectedPage);
      const page = selectedPage + 1;
      onMoveToPage?.(page, isFirstLoading);
      if (isFirstLoading) setIsFirstLoading(false); // variabile ausiliaria per gestire il primo caricamento

      if (action === 'reset')
        setPagination({
          current_page: 1,
          last_page: 1,
          per_page: 0,
          total: 0,
        });
      else setPagination({ ...pagination, current_page: page });
    },
    [isFirstLoading, onMoveToPage, pagination],
  );

  useEffect(() => {
    onMoveSpecificPage(0, 'reset');

    const isChanged = JSON.stringify(searchParams) !== JSON.stringify(searchParamsState);

    if (isChanged)
      setSearchParamsState({
        ...searchParamsState,
        ...searchParams,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      onResults?.(data ?? []);

      setPagination({
        current_page: Number(data?.pagination?.current_page) ?? 0,
        last_page: Number(data?.pagination?.last_page) ?? 0,
        per_page: Number(data?.pagination?.per_page) ?? 0,
        total: Number(data?.pagination?.total) ?? 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    onFetching?.(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return useMemo(() => {
    return (
      <div className={clsx('flex justify-center', { hidden: !pagination.total || pagination.total <= 1 })}>
        <ViewPaginate
          onMoveSpecificPage={(selectedItem) => onMoveSpecificPage(selectedItem.selected)}
          pagination={pagination}
          textPageClassName={textClassName}
          activePageClassName={activeClassName}
        />
      </div>
    );
  }, [pagination, textClassName, activeClassName, onMoveSpecificPage]);
}

export default Pagination;
