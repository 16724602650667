import React, { memo, useEffect } from 'react';
import FilterButton from '@/components/core/buttons/FilterButton';
import { useTranslation } from 'react-i18next';

export type CategoryFilterType = {
  id: string;
  name: string;
  url?: string;
  type?: string;
  permalink?: string;
};

export type GalFilterType = {
  id: string;
  name: string;
  url?: string;
};

interface FilterProps {
  categoryFilters?: CategoryFilterType[];
  selectedCategory?: string;
  className?: string;
  onSelectCategory?: (id: string) => void;
}

const FilterBar = ({ categoryFilters, className = '', selectedCategory, onSelectCategory }: FilterProps) => {
  const [selectedCat, setSelectedCat] = React.useState<string>(selectedCategory ?? '');

  const { t } = useTranslation();

  const handleSelectCat = (id: string) => {
    setSelectedCat(id);
    onSelectCategory?.(id);
  };

  useEffect(() => {
    if (!selectedCategory || selectedCategory === selectedCat) return;
    setSelectedCat(selectedCategory ?? '');
  }, [selectedCategory]);

  if (!categoryFilters) return null;

  return (
    <>
      {categoryFilters?.map((item, index) => (
        <FilterButton
          key={item.id}
          title={t(item.name).toString()}
          item={item}
          selected={selectedCat === item.id}
          onClick={() => handleSelectCat(item.id)}
          className={'capitalize ' + className}
        />
      ))}
    </>
  );
};

export default memo(FilterBar);
