import { ReactComponent as Animali } from '@/assets/images/icons/achive-subheader/agriturismi/animali-icon.svg';
import { ReactComponent as Bicchieri } from '@/assets/images/icons/achive-subheader/cantine/bicchieri-icon.svg';
import { ReactComponent as Ciliegia } from '@/assets/images/icons/achive-subheader/frantoi/ciliegia-icon.svg';
import { ReactComponent as Foglie } from '@/assets/images/icons/achive-subheader/frantoi/foglie-icon.svg';
import { ReactComponent as Oliva } from '@/assets/images/icons/achive-subheader/frantoi/oliva-icon.svg';
import { ReactComponent as Pentola } from '@/assets/images/icons/achive-subheader/ristoranti/pentola-icon.svg';
import { ReactComponent as FogliaCiliegia } from '@/assets/images/icons/achive-subheader/frantoi/foglia-ciliegia-icon.svg';
import { ReactComponent as FrantoioOliva } from '@/assets/images/icons/achive-subheader/frantoi/frantoio-oliva-icon.svg';

type FilieraSubHeaderIconsType =
  | 'animali'
  | 'bicchieri'
  | 'ciliegia'
  | 'foglie'
  | 'oliva'
  | 'pentola'
  | 'foglia-ciliegia'
  | 'frantoio-oliva';

const FilieraSubHeaderIcons: Record<FilieraSubHeaderIconsType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  animali: Animali,
  bicchieri: Bicchieri,
  ciliegia: Ciliegia,
  foglie: Foglie,
  oliva: Oliva,
  pentola: Pentola,
  'foglia-ciliegia': FogliaCiliegia,
  'frantoio-oliva': FrantoioOliva,
};

export default FilieraSubHeaderIcons;
