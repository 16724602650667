export const prepareUrl = (url: string, searchParam: any) => {
  let urlWithParam = url;
  if (searchParam) {
    const fullUrl = new URL(url, process.env.NEXT_PUBLIC_API_BASE_URL);

    Object.keys(searchParam).forEach((key) => {
      if (searchParam[key]) {
        fullUrl.searchParams.append(key, searchParam[key]);
      }
    });

    urlWithParam = fullUrl.toString();
  }
  return urlWithParam;
};
