import clsx from 'clsx';
import React, { memo } from 'react';
import { ClipLoader } from 'react-spinners';

interface LoaderMapProps {
  isLoading?: boolean;
  className?: string;
  closeModal?: () => void;
}

const LoaderMap = ({ closeModal, className, isLoading }: LoaderMapProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={clsx(
        'absolute inset-0  flex animate-twPulse items-center justify-center bg-stone-300 animate-infinite',
        className,
      )}
    >
      <span className="scale-150">
        <ClipLoader color="#A91314" />
      </span>
    </div>
  );
};

export default memo(LoaderMap);
