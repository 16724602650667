import calabriaSvg from '@/assets/images/gal/Calabria.png';
import areaGrecanica from '@/assets/images/gal/Area-Gracanica.png';
import bassoTirrenoReggino from '@/assets/images/gal/Basso tirreno reggino.png';
import dueMari from '@/assets/images/gal/Due mari.png';
import galSila from '@/assets/images/gal/Gal Sila.png';
import kroton from '@/assets/images/gal/Kroton.png';
import pollinoSviluppo from '@/assets/images/gal/Pollino Sviluppo.png';
import rivieraDeiCedri from '@/assets/images/gal/Riviera dei cedri.png';
import serreCalabresi from '@/assets/images/gal/Serre calabresi.png';
import sibaritide from '@/assets/images/gal/Sibaritide.png';
import sts from '@/assets/images/gal/STS (Savuto-Tirreno-Serre).png';
import terreLocridee from '@/assets/images/gal/Terre locridee.png';
import terreVibonesi from '@/assets/images/gal/Terre vibonesi.png';
import valleCrati from '@/assets/images/gal/Valle Crati.png';
import catanzaro from '@/assets/images/gal/catanzaro.png';
import crotone from '@/assets/images/gal/crotone.png';
import gioiaTauro from '@/assets/images/gal/gioia.png';
import lameziaTerme from '@/assets/images/gal/lamezia.png';
import reggioCalabria from '@/assets/images/gal/reggio calabria.png';
import viboValentia from '@/assets/images/gal/vibo.png';

import cosenza from '@/assets/images/gal/cosenza.png';

import { StaticImageData } from 'next/image';

const GalMapping: Record<string, string | StaticImageData> = {
  '1000': calabriaSvg,
  '1485': rivieraDeiCedri,
  '1486': sibaritide,
  '1487': pollinoSviluppo,
  '1488': galSila,
  '1489': valleCrati,
  '1490': sts,
  '1491': kroton,
  '1492': dueMari,
  '1493': terreVibonesi,
  '1494': serreCalabresi,
  '1495': bassoTirrenoReggino,
  '1496': terreLocridee,
  '1497': areaGrecanica,
  '5955': catanzaro,
  '5956': cosenza,
  '5957': crotone,
  '5960': gioiaTauro,
  '5961': lameziaTerme,
  '5958': reggioCalabria,
  '5959': viboValentia,
};

// const GalMapping: Record<string, string | StaticImageData> = {
//   '1000': calabriaSvg,
//   '1485': rivieraDeiCedri,
//   '1486': sibaritide,
//   '1487': pollinoSviluppo,
//   '1488': galSila,
//   '1489': valleCrati,
//   '1490': sts,
//   '1491': kroton,
//   '1492': dueMari,
//   '1493': terreVibonesi,
//   '1494': serreCalabresi,
//   '1495': bassoTirrenoReggino,
//   '1496': terreLocridee,
//   '1497': areaGrecanica,
// };

export default GalMapping;
