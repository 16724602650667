import DynamicLoader from '@/components/core/spinner/DynamicLoader';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

const DynamicAnimation = dynamic(() => import('@/components/shared/utility/animations/AnimationNotFound'), {
  ssr: false,
  loading: () => <DynamicLoader />,
});

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-[350px] w-full flex-col items-center justify-center space-y-8">
      <div>
        <DynamicAnimation />
      </div>
      <div>
        <h1 className="text-center text-2xl">{t('Nessun risultato')}</h1>
        <p className="text-base text-stone-400">{t('Prova a cambiare i parametri di ricerca')}</p>
      </div>
    </div>
  );
};

export default NotFound;
