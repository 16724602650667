import { SearchPaginationFetch } from '@/models/interfaces/utils/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';

interface ViewPaginateProps {
  pagination: SearchPaginationFetch;
  textPageClassName?: string;
  activePageClassName?: string;
  onMoveSpecificPage: (selectedItem: { selected: number }) => void;
}

const ViewPaginate = ({
  pagination,
  textPageClassName,
  activePageClassName,
  onMoveSpecificPage,
}: ViewPaginateProps) => {
  return (
    <div className="paginate flex max-w-full flex-col items-center">
      <ReactPaginate
        previousLabel={
          <button
            disabled={pagination.current_page === 1}
            className={[
              'cursor-pointer rounded-full p-1 disabled:cursor-not-allowed disabled:opacity-60',
              textPageClassName,
            ].join(' ')}
          >
            <ChevronLeftIcon className="mr-0.5 h-5 w-5" />
          </button>
        }
        previousLinkClassName={`h-10 w-10 flex justify-center items-center rounded-full enabled:hover:scale-125 transition-all duration-300`}
        nextLabel={
          <button
            disabled={pagination.current_page === pagination.last_page}
            className={[
              'cursor-pointer rounded-full p-1 disabled:cursor-not-allowed disabled:opacity-60',
              textPageClassName,
            ].join(' ')}
          >
            <ChevronRightIcon className="ml-0.5 h-5 w-5 " />
          </button>
        }
        nextLinkClassName={`h-10 w-10 flex justify-center items-center rounded-full enabled:hover:scale-125 transition-all duration-300`}
        breakLabel={'...'}
        breakLinkClassName={'h-10 w-5 flex justify-center items-center rounded-full'}
        pageCount={pagination?.last_page ?? 0}
        containerClassName="flex"
        className={['flex gap-1 rounded-full text-center font-semibold transition-all duration-300 '].join(
          textPageClassName,
        )}
        onPageChange={onMoveSpecificPage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        activeClassName={`${activePageClassName}`}
        pageClassName={'rounded-full ' + textPageClassName}
        pageLinkClassName={
          'h-10 w-10 flex justify-center items-center rounded-full text-center font-semibold transition-all duration-200 '
        }
        breakClassName={'!bg-transparent ' + textPageClassName}
        forcePage={pagination?.current_page ? pagination?.current_page - 1 : 0}
      />
    </div>
  );
};

export default memo(ViewPaginate);
