/* eslint-disable react/display-name */
import Breadcrumbs from '@/components/core/breadcrumbs/Breadcrumbs';
import { EntityType, FilieraEntityType } from '@/models/entity-type/EntityType';
import React, { forwardRef, memo, useMemo } from 'react';
import FilieraSubHeaderIcons from '../utils/icons';
import IconMapping from '@/components/utils/iconMapping';

interface DynamicSubHeaderProps {
  entity: FilieraEntityType;
  title: string;
  subtitle: string;
  breadcrumbs: Record<string, string>;
}

const iconsSubHeader: Record<
  FilieraEntityType,
  {
    left: React.FC<React.SVGProps<SVGSVGElement>>;
    right: React.FC<React.SVGProps<SVGSVGElement>>;
  }
> = {
  [EntityType.AGRITURISMI]: {
    left: IconMapping.agriturismi.thin,
    right: FilieraSubHeaderIcons.animali,
  },
  [EntityType.CANTINE]: {
    left: IconMapping.cantine.thin,
    right: FilieraSubHeaderIcons.bicchieri,
  },
  [EntityType.FRANTOI]: {
    left: FilieraSubHeaderIcons['frantoio-oliva'],
    right: FilieraSubHeaderIcons['foglia-ciliegia'],
  },
  [EntityType.RISTORANTI]: {
    left: IconMapping.ristoranti.thin,
    right: FilieraSubHeaderIcons.pentola,
  },
  [EntityType.RISTORAZIONE]: {
    left: IconMapping.ristoranti.thin,
    right: FilieraSubHeaderIcons.pentola,
  },
};

const DynamicSubHeader = forwardRef<HTMLDivElement, DynamicSubHeaderProps>((props, ref) => {
  const { title, subtitle, entity, breadcrumbs } = props;

  const LeftIcon = useMemo(() => {
    return iconsSubHeader[entity].left;
  }, [entity]);

  const RightIcon = useMemo(() => {
    return iconsSubHeader[entity].right;
  }, [entity]);

  return (
    <div
      ref={ref}
      className="relative flex items-center overflow-hidden bg-primary-500 py-8 transition-all duration-700 lg:h-52"
    >
      <div className="container z-10 flex flex-col gap-6">
        <div className="animate-fadeInDown">
          <Breadcrumbs breadcrumbs={breadcrumbs} className="text-sm lg:text-lg" textColorClass="text-white" />
        </div>

        <div className="relative flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between lg:gap-24">
          <div className="relative animate-fadeInLeft">
            <span className="absolute inset-0 w-2 rounded-full bg-white/40" />

            <h1 className="translate-x-4 text-4xl text-white first-letter:uppercase lg:text-5xl">{title}</h1>
          </div>

          <div className="animate-fadeInUpBig animate-duration-[1200ms]">
            <p className=" text-white ">{subtitle}</p>
          </div>

          {/* Search Bar */}
          {/* <div className="flex h-fit w-2/3 items-center gap-4">
              <label htmlFor="search-bar" className="relative w-full">
                <input
                  id="search-bar"
                  type="search"
                  placeholder={t('Inserisci una destinazione/GAL').toString()}
                  className="h-11 w-full rounded-md px-4 py-2 pl-10 outline-none
                ring-primary-500 ring-opacity-80
                  focus:ring-2"
                />

                <MdLocationPin className="absolute left-2 top-1.5 h-7 w-7" />
              </label>
              <button
                type="button"
                className="flex h-11 items-center rounded-md border border-primary-500 bg-primary-500 px-6 text-white"
              >
                {t('cerca')}
              </button>
            </div> */}
        </div>
      </div>

      {/* Left Icon */}
      <div className="absolute inset-y-4 left-0 hidden h-full w-full opacity-10 lg:w-1/2 xl:block 3xl:opacity-50">
        <LeftIcon className="absolute left-[10%] top-0 h-[80%] object-cover text-white" />
      </div>

      {/* Right Icon */}
      <div className="absolute inset-y-4 right-[5%] h-full scale-75 opacity-10 lg:opacity-10 3xl:opacity-50">
        <RightIcon className="absolute right-0 top-0 h-[80%] object-cover text-white" />
      </div>
    </div>
  );
});

export default memo(DynamicSubHeader);
