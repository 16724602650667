import clsx from 'clsx';
import React, { memo } from 'react';

interface FilterButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  title: string;
  item: any;
  className?: string;
  selected?: boolean;
  onClick?: (item: any) => void;
}

const FilterButton = ({ title, selected, className, item, onClick, ...props }: FilterButtonProps) => {
  return (
    <button
      className={clsx(
        'flex snap-center items-center gap-2 whitespace-nowrap rounded-full border-2 px-3.5 py-1 text-sm font-light text-stone-400 last:mr-8 ',
        className,
        {
          'border-primary-500 bg-primary-500 text-white hover:border-primary-600 hover:bg-primary-600':
            selected,
          'hover:border-primary-500 hover:bg-stone-50 dark:hover:bg-stone-700': !selected,
        },
      )}
      onClick={() => onClick?.(item)}
    >
      {title}
    </button>
  );
};

export default memo(FilterButton);
