import GalMapping from '@/components/utils/galMapping';
import useBreakPoint from '@/hook/useBreakPoint';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';
import { Fragment, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GalMenuProps {
  selected: string;
  selectedName?: string;
  menu?: { id: string; name: string }[];
  onSelect: (id: string) => void;
}

const GalMenu = ({ onSelect, selected, selectedName, menu }: GalMenuProps) => {
  const [selectedGal, setSelectedGal] = useState(selected);

  const { t } = useTranslation();

  const handleSelect = (id: string) => {
    onSelect(id);
    setSelectedGal(id);
  };

  useEffect(() => {
    if (selected !== selectedGal) setSelectedGal(selected || '1000');
  }, [selected]);

  if (!menu?.length) return null;

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button>
            <span
              className={clsx(
                'group flex min-w-[100px] items-center gap-2 whitespace-nowrap rounded-xl px-4 py-2  text-2xl font-light hover:bg-gray-50 dark:hover:bg-stone-900',
                'text-stone-400 dark:text-gray-100',
                'focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75',
              )}
            >
              {/* <span className="mr-2 h-16 w-1.5 rounded-full bg-primary-500" /> */}
              <Image
                blurDataURL="/placeholder.png"
                placeholder="blur"
                src={GalMapping[selectedGal]}
                alt=""
                width={32}
                height={32}
              />
              <p className="!text-xl text-inherit group-hover:text-dark dark:group-hover:text-white">
                {selectedName
                  ? selectedName
                  : t(menu?.find((item) => item.id === selectedGal)?.name ?? '').toString() ||
                    t('Seleziona Territorio')}
                {/* <span className="block text-xs">{selected}</span> */}
              </p>
              {/* <FiInfo
                className="absolute right-2 top-2 h-3.5 w-3.5 text-xs hover:text-sky-500"
                data-tooltip-id="my-tooltip"
              />
              <CustomTooltip
                id="my-tooltip"
                className={clsx(
                  'shadow-full !z-50 whitespace-normal !rounded-2xl !bg-light !opacity-100 animate-duration-500 dark:!bg-dark',
                  {
                    'animate-zoomInLeft': greaterThanLg,
                    'animate-fadeIn': !greaterThanLg,
                  },
                )}
                place={greaterThanLg ? 'left' : 'top'}
              >
                <div className="max-w-xs rounded-md p-2 dark:bg-dark lg:max-w-sm">
                  <p className="text-xs">
                    <span className="font-semibold">GAL</span> (Gruppo di Azione Locale) è un’associazione di
                    enti pubblici e privati che operano in un territorio rurale per la realizzazione di un
                    programma di sviluppo locale partecipato.
                  </p>
                </div>
              </CustomTooltip> */}
            </span>
          </Menu.Button>

          <Transition
            as={Fragment}
            show={open}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-90"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-90"
          >
            <Menu.Items
              className={clsx(
                'absolute left-0 mt-2 w-[calc(100vw_-_2.5rem)] max-w-7xl origin-top-left rounded-3xl bg-white/100 p-2 ring-1 ring-black ring-opacity-5 backdrop-blur-sm focus:outline-none dark:bg-dark/100 lg:p-4 ',

                {
                  'z-50': open,
                  'z-10 lg:z-20': !open,
                },
              )}
            >
              {/* <div className="mb-4 flex items-center gap-1 rounded-md p-2 dark:bg-dark">
                <FiInfo className=" right-2 top-2 h-3.5 w-3.5 text-xs hover:text-sky-500" />
                <p className="text-xs md:text-sm">
                  {t(
                    'GAL (Gruppo di Azione Locale) è un’associazione di enti pubblici e privati che operano in un territorio rurale per la realizzazione di un programma di sviluppo locale partecipato',
                  )}
                </p>
              </div> */}
              <div
                className={clsx(
                  'h-auto w-full',
                  'grid grid-cols-2 flex-wrap gap-2 sm:grid-cols-3 lg:grid-cols-4 lg:gap-3 xl:grid-cols-5 2xl:grid-cols-6',
                )}
              >
                {menu?.map((item, index) => (
                  <Menu.Item key={'menu-item' + index.toString()}>
                    {({ active, close, disabled }) => (
                      <span key={'menu-item-children' + index.toString()}>
                        <button
                          key={item.id}
                          className={clsx(
                            'relative col-span-2 flex aspect-[7/5] w-full flex-col justify-between gap-1 rounded-2xl border-2 bg-gray-50 p-2.5 duration-200 ease-in-out hover:border-dark/50 dark:bg-stone-900',
                            {
                              'border-primary-500': selected === item.id,
                            },
                          )}
                          onClick={() => {
                            handleSelect(item.id);
                            close();
                          }}
                        >
                          <div className="relative block h-4/6 w-full">
                            <Image
                              blurDataURL="/placeholder.png"
                              placeholder="blur"
                              src={GalMapping[item.id]}
                              alt={item.name}
                              fill
                              className="object-contain"
                            />
                          </div>
                          <div className="relative flex h-2/6 w-full items-center justify-center">
                            <span className="leading-1 text-sm font-light text-gray-500 dark:text-gray-400">
                              {item.name}
                            </span>
                          </div>
                        </button>
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default memo(GalMenu);
