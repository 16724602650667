import CustomDateRangePicker from '../custom-date-range-picker/CustomDateRangePicker';
import { CustomDropDownMenu } from '../custom-dropdown-menu/CustomDropDownMenu';
import { memo, useCallback, useEffect, useState } from 'react';
import { CalendarIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

interface ButtonDateRangePickerProps {
  align?: 'left' | 'right';
  startDate?: Date | null;
  endDate?: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

const ButtonDateRangePicker = ({
  onEndDateChange,
  onStartDateChange,
  align,
  startDate,
  endDate,
}: ButtonDateRangePickerProps) => {
  const [startDateLocal, setStartDateLocal] = useState<Date | null>();
  const [endDateLocal, setEndDateLocal] = useState<Date | null>();

  const { t } = useTranslation();

  const handleStartDateChange = (date: Date | null) => {
    if (!date) return;

    setStartDateLocal(date);
  };

  const handleEndDateChange = (date: Date | null, close: () => void) => {
    if (!date || !startDateLocal) return;
    setEndDateLocal(date);

    // chiamiamo anche onStartDateChange in questo punto per notificare il cambiamento delle date al componente padre (in questo caso è il componente che gestisce il filtro)
    onStartDateChange(startDateLocal);
    onEndDateChange(date);

    close();
  };

  const TriggerButton = useCallback(() => {
    const isSelectedDate = startDate && endDate;

    const text = isSelectedDate
      ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
      : t('Seleziona un intervallo di date');

    return (
      <span
        className={clsx(
          ' flex items-center justify-center gap-2 whitespace-nowrap rounded-full border-2 p-2 px-4 text-sm transition-all  duration-150 hover:border-primary-500 active:scale-95',
          {
            'text-stone-400': !isSelectedDate,
            // 'border-primary-500 bg-primary-500 !font-medium text-white': isSelectedDate,
          },
        )}
      >
        <CalendarIcon className="h-4 w-4 lg:h-5 lg:w-5" />
        <span className="hidden text-inherit lg:block"> {text}</span>
      </span>
    );
  }, [endDate, startDate]);

  useEffect(() => {
    if (startDateLocal !== startDate) {
      setStartDateLocal(startDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (endDateLocal !== endDate) {
      setEndDateLocal(endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return (
    <div className="relative">
      <CustomDropDownMenu
        Trigger={TriggerButton}
        align={align}
        wrapperPanelClassName="!w-80 wrapper-nice-date lg:!w-96 -translate-x-32 sm:translate-x-0 transition-all duration-300 overflow-hidden overflow-y-auto "
        panelClassName="grid grid-cols-1 p-3 gap-4"
      >
        {[''].map((item) => ({
          child(_active, close, _disabled) {
            return (
              <CustomDateRangePicker
                onStartDateChange={(date) => handleStartDateChange(date)}
                onEndDateChange={(date) => handleEndDateChange(date, close)}
                startDate={startDateLocal}
                endDate={endDateLocal}
              />
            );
          },
        }))}
      </CustomDropDownMenu>
    </div>
  );
};

export default memo(ButtonDateRangePicker);
